import {useContext, useMemo, useState} from "react";
import {getSubmissionHandlingFields, HONEY_POT_FIELD_NAME} from "../../components/website-form";
import Form from "@autocx/forms";
import classNames from "classnames";
import {textAlignment} from "../../utils/block-utils";
import {WebsiteContext} from "../../index";
import Currency from "@autocx/forms/src/controls/currency";

import _get from 'lodash/get'
import _set from 'lodash/set'
import _unset from "lodash/unset";
import axios from "axios";

/**
 * The Enquiry purchase method UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function EnquiryPurchaseMethod(props) {
    const context = useContext(WebsiteContext)

    const [state, setState] = useState(() => {
        return {
            error: false,
            submitted: false,
            submitting: false,
            form: props.values?.form || {}
        }
    })

    const fieldsets = useMemo(() => {
        const fields = getSubmissionHandlingFields(props.values?.form?.rules, false, HONEY_POT_FIELD_NAME, context);
        return fields?.length > 0 ? [{fields}] : [];
    }, []);

    return (
        state.submitted ? (
            <div className={classNames("flex flex-col w-full gap-1", textAlignment(props))}>
                <div>
                    <div className={"inline-block"}>
                        <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" className={"w-14"}>
                            <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd"
                               strokeLinecap="round" strokeLinejoin="round">
                                <path className="form-submit-circle"
                                      d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
                                <path className="form-submit-tick" d="M6.5 14.5L10 18 l8.808621-8.308621"/>
                            </g>
                        </svg>
                    </div>
                </div>
                {props?.form?.customSuccessMessage
                    ?
                    <div dangerouslySetInnerHTML={{__html: props?.form?.customSuccessMessage}} className={"rich-text"}/>
                    :
                    <p>Thank you, we've received your submission.</p>
                }
            </div>
        ) : (
            <Form
                id={props.values.form?.uuid || props.values.form?._id}
                noValidate={false}
                fieldsets={fieldsets}
                userDefinedFieldsets={props.values.form?.userDefinedFieldsets}
                layout={"website"}
                className={"flex flex-col w-full"}
                onSubmit={(e, values) => {
                    setState(previous => ({...previous, submitting: true}));

                    const honeyPotElem = document.querySelector(`#${HONEY_POT_FIELD_NAME}`);

                    // If there is any sign of a value on the honey pot input then ignore submission and go to success state
                    if (honeyPotElem.value || honeyPotElem._valueTracker?.getValue()) {
                        setState(previous => ({...previous, submitted: true, error: false, submitting: false}))
                        return;
                    }

                    values.form = {uuid: e.target.id};
                    values.product = {id: props.values?.uuid || props.id};

                    // Remove all whitespace from the number if it's set when the phone number is present
                    const matchBy = state.form.rules?.matchBy;
                    if (matchBy === 2 || matchBy === 3) {
                        const number = _get(values, 'customer.contactNumber');
                        if (number) {
                            _set(values, 'customer.contactNumber', number.replace(/ +/g, '').trim());
                        } else {
                            _unset(values, 'customer.contactNumber')
                        }
                    }
                    return axios
                        .post(`/api/v2/channels/${context.channel}/responses`, values)
                        .then(() => {
                            if (props?.values?.form?.formSubmission) new Function(props.values.form.formSubmission)()
                            setState(previous => ({...previous, submitted: true, submitting: false}))
                        })
                        .catch(err => {
                            console.error(err);
                            setState(previous => ({...previous, error: true, submitting: false}))
                        });
                }}
            >
                {/* This is here to prevent spam bots */}
                <p hidden>
                    <input
                        id={HONEY_POT_FIELD_NAME}
                        type="text"
                        name={HONEY_POT_FIELD_NAME}
                        autoComplete="off"
                    />
                </p>

                {state.error ? (
                    <span className={"text-red-500"}>
                    There was a problem sending the message, please try again.
                </span>
                ) : null}
                <button
                    type="submit"
                    className={classNames("button hover:opacity-80 w-full justify-center mt-4 relative", state.submitting ? "pointer-events-none" : null)}
                    form={props.values.form?.uuid}
                >
                    <span
                        className={classNames("transition ease-in duration-200", state.submitting ? "opacity-50" : "opacity-100")}>Send Enquiry</span>
                    {state.submitting ? (
                        <svg className={"absolute animate-spin h-5 w-5 right-0 mr-4 m-auto"}
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"/>
                            <path className="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                        </svg>
                    ) : null}
                </button>
            </Form>
        ))
}


export function scrollToForm(e) {
    const firstNameInput = document.querySelector('input[name="customer.firstName"]');
    const formContainer = document.getElementById("purchase-enquiry")
    if (firstNameInput) {
        firstNameInput.focus();
    }
    formContainer.scrollIntoView()
}

export function EnquireButton({className}) {
    return (
        <button className={classNames(className,"button shrink-0")} onClick={(e) => scrollToForm(e)}>
            Enquire Now
        </button>
    )
}

/**
 * The UI for Mobile/Small Screen CTA on producrt pages
 * @param context
 * @param className
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function EnquiryActionBanner({context, className, salesEvent, ...props}) {
    return (
        <>
            {/*Handle showing sale prices. Hide sale price if it's 0 and there's a sales event*/}
            {!props?.values?.productTemplate?.hidePriceOnWebsite && ((salesEvent && props.values.sellingPrice !== 0) || !salesEvent) ?
                <div className={classNames("flex flex-col truncate", className)}>
                    <div>
                        {props.values?.discountedSellingPrice ? (
                            <span className={classNames(
                                "text-2xl",
                                "font-semibold"
                            )}>
                                    <Currency.DisplayValue currency={context.currency}
                                                           value={props.values?.discountedSellingPrice}
                                                           emptyPlaceholder={""}/>
                                </span>
                        ) : null}
                        <span
                            className={classNames("text-xl", props.values?.discountedSellingPrice ? "line-through ml-2 opacity-80" : "font-semibold")}>
                                {props.values?.sellingPrice ?
                                    <Currency.DisplayValue currency={context.currency}
                                                           value={props.values?.sellingPrice}
                                                           emptyPlaceholder={""}/>
                                    : "Price on Application"}
                        </span>
                    </div>
                    {props.values?.sellingPriceDisclaimer ? (
                        <div className="truncate pr-4">
                            {props.values?.sellingPriceDisclaimer}
                        </div>
                    ) : null}
                </div>
            :null}
            <EnquireButton className={"h-12"}/>
        </>
    )
}