import {useEffect, useState} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router";
import {BrowserRouter, NavLink} from "react-router-dom";
import AccountProfile from "./account-profile";
import AccountOrders from "./account-orders";
import './index.css';
import AccountBidsOffers from "./account-bids-offers";
import classNames from "classnames";
import axios from "axios";
import AccountLoginRegister from "./account-login-register";
import Spinner from "../../components/spinner";
import AccountDigitalProducts from "./account-digital-products";


AccountBlock.propTypes = {};

AccountBlock.defaultProps = {};

const ProtectedRoute = ({component: Component, isRegistered, ...rest}) => {
    const location = useLocation();

    if (!isRegistered) {
        // Redirect to login if not registered
        return <Navigate to="/my-account/sign-in" state={{from: location}} replace/>;
    }

    // Render the component if the user is registered
    return <Component {...rest} />;
};

const AccountNavigation = ({profile}) => {
    // using name routerLocation in this instance to not clash with native browser location
    const routerLocation = useLocation();
    const isOnAccountRoot = routerLocation.pathname === '/my-account' || routerLocation.pathname === '/my-account/';

    function logout() {
        axios.post('/api/v2/publicusers/logout')
            .then(() => {
                location.replace(location.origin)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    return (
        <nav
            className="account-navigation inline-flex flex-row flex-wrap mb-8 sm:mt-2 sm:flex sm:flex-col sm:space-y-1 sm:w-60 sm:mr-8">
            <NavLink to="/my-account/profile"
                     className={classNames(isOnAccountRoot && "active", "flex flex-row items-center space-x-2")}
            >
                <div className={"w-5 flex flex-row items-center"}>
                    <svg width="18" height="18" viewBox="0 0 24 24">
                        <path fill="currentColor"
                              d="M12.105 24.21C18.7369 24.21 24.2121 18.7273 24.2121 12.105C24.2121 5.47312 18.7273 0 12.0954 0C5.47523 0 0 5.47312 0 12.105C0 18.7273 5.48484 24.21 12.105 24.21ZM12.105 21.8255C6.71085 21.8255 2.39412 17.4991 2.39412 12.105C2.39412 6.71085 6.70124 2.38452 12.0954 2.38452C17.4895 2.38452 21.8276 6.71085 21.8276 12.105C21.8276 17.4991 17.4991 21.8255 12.105 21.8255ZM19.9181 19.8361L19.8766 19.6418C19.14 17.9212 16.196 16.1442 12.105 16.1442C8.02569 16.1442 5.07959 17.9212 4.33545 19.6322L4.29397 19.8361C6.49826 21.8651 9.86835 22.9826 12.105 22.9826C14.3534 22.9826 17.6946 21.8747 19.9181 19.8361ZM12.105 14.2132C14.3794 14.2324 16.1414 12.2979 16.1414 9.78468C16.1414 7.4196 14.3655 5.4478 12.105 5.4478C9.84656 5.4478 8.05898 7.4196 8.0707 9.78468C8.08031 12.2979 9.84234 14.194 12.105 14.2132Z"/>
                    </svg>
                </div>
                <span>Profile</span>
            </NavLink>
            <NavLink to="/my-account/orders" className={"flex flex-row items-center space-x-2"}>
                <div className={"w-5 flex flex-row items-center"}>
                    <svg width="20" height="20" viewBox="0 0 26 26">
                        <path
                            d="M1.68492 20.4128L10.9624 25.595C11.6039 25.9574 12.2609 25.9574 12.9141 25.595L22.1798 20.4128C23.3086 19.7844 23.8669 19.1232 23.8669 17.4686L23.8669 7.99747C23.8669 6.74708 23.4047 5.95583 22.3612 5.37012L14.1213 0.749887C12.6717-0.065035 11.1952-0.065035 9.74555 0.749887L1.51523 5.37012C0.462187 5.95583 0 6.74708 0 7.99747L0 17.4686C0 19.1232 0.567891 19.7844 1.68492 20.4128ZM2.95265 18.6128C2.31093 18.2589 2.08499 17.8794 2.08499 17.2644L2.08499 8.71839L10.8511 13.6696L10.8511 23.0734ZM20.9217 18.6128L13.0137 23.0734L13.0137 13.6696L21.7819 8.71839L21.7819 17.2644C21.7819 17.8794 21.5559 18.2589 20.9217 18.6128ZM11.9372 11.7547L3.26998 6.8952L6.54093 5.03613L15.2166 9.90856ZM17.4323 8.68185L8.73842 3.8148L10.6784 2.71675C11.5301 2.23698 12.3326 2.22315 13.196 2.71675L20.6065 6.8952Z"
                            fill="currentColor"/>
                    </svg>
                </div>
                <span>Orders</span>
            </NavLink>
            {profile?.customer?._links?.bids &&
                <NavLink to="/my-account/bids-offers" className={"flex flex-row items-center space-x-2"}>
                    <div className={"w-5 flex flex-row items-center"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                            <g fillRule="evenodd" transform="rotate(-45 12.293 11.879)">
                                <path
                                    d="M12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z"/>
                                <path fillRule="nonzero"
                                      d="M4.02099609,12.4511719 C4.35058594,12.4511719 4.6875,12.2827148 4.6875,11.8139648 L4.6875,11.3525391 C6.73828125,11.1108398 8.02001953,9.90966797 8.02001953,8.17382813 C8.02001953,6.67236328 7.11181641,5.75683594 5.26611328,5.39794922 L3.88183594,5.11230469 C2.95166016,4.92919922 2.52685547,4.57763672 2.52685547,4.02832031 C2.52685547,3.41308594 3.07617188,2.92236328 3.94042969,2.92236328 C4.67285156,2.92236328 5.20019531,3.15673828 5.82275391,3.75 C6.18896484,4.06494141 6.48925781,4.20410156 6.88476563,4.20410156 C7.34619141,4.20410156 7.72705078,3.8671875 7.72705078,3.38378906 C7.72705078,2.97363281 7.50732422,2.54150391 7.09716797,2.15332031 C6.55517578,1.62597656 5.68359375,1.2890625 4.70947266,1.16455078 L4.70947266,0.637207031 C4.70947266,0.168457031 4.37255859,0 4.04296875,0 C3.71337891,0 3.37646484,0.161132813 3.37646484,0.637207031 L3.37646484,1.14990234 C1.39892578,1.33300781 0.124511719,2.51953125 0.124511719,4.21142578 C0.124511719,5.70556641 1.01806641,6.65771484 2.79052734,7.02392578 L4.16748047,7.32421875 C5.22216797,7.55126953 5.63964844,7.87353516 5.63964844,8.42285156 C5.63964844,9.12597656 5.08300781,9.58740234 4.05029297,9.58740234 C3.31054688,9.58740234 2.59277344,9.27978516 1.94091797,8.68652344 C1.5234375,8.33496094 1.25976563,8.23242188 0.9375,8.23242188 C0.417480469,8.23242188 0,8.5546875 0,9.12597656 C0,9.56542969 0.234375,9.99023438 0.64453125,10.3637695 C1.25244141,10.9277344 2.23388672,11.2719727 3.34716797,11.3671875 L3.34716797,11.8139648 C3.34716797,12.2827148 3.69140625,12.4511719 4.02099609,12.4511719 Z"
                                      transform="translate(8.035 5.728)"/>
                                <rect width="5" height="10" x="9.5" y="22" rx="2.5"/>
                            </g>
                        </svg>
                    </div>
                    <span>Bids & Offers</span>
                </NavLink>
            }
            {/*<NavLink to="/my-account/courses">*/}
            {/*    Courses*/}
            {/*</NavLink>*/}
            <NavLink to="/my-account/digital-products" className={"flex flex-row items-center space-x-2"}>
                <div className={"w-5 flex flex-row items-center"}>
                    <svg width="18" height="18" viewBox="0 0 22 22">
                        <g>
                            <path
                                d="M3.84421 21.8972L18.0295 21.8972C20.5685 21.8972 21.8737 20.5919 21.8737 18.0914L21.8737 3.82921C21.8737 1.32656 20.5685 0.0234375 18.0295 0.0234375L3.84421 0.0234375C1.31484 0.0234375 0 1.31695 0 3.82921L0 18.0914C0 20.6016 1.31484 21.8972 3.84421 21.8972ZM3.97733 19.6013C2.88772 19.6013 2.29592 19.0371 2.29592 17.8985L2.29592 4.01999C2.29592 2.88139 2.88772 2.31936 3.97733 2.31936L17.8964 2.31936C18.9764 2.31936 19.5778 2.88139 19.5778 4.01999L19.5778 17.8985C19.5778 19.0371 18.9764 19.6013 17.8964 19.6013Z"
                                fill="currentColor"/>
                            <path
                                d="M10.9523 4.94203C10.362 4.94203 9.91523 5.36015 9.91523 5.95171L9.91523 11.4905L10.0237 13.8977L8.96578 12.5822L7.64718 11.2118C7.48007 11.0224 7.23117 10.9118 6.94382 10.9118C6.39586 10.9118 5.9925 11.3088 5.9925 11.8484C5.9925 12.1324 6.07125 12.3581 6.25734 12.5527L10.1313 16.4644C10.3931 16.7325 10.643 16.8569 10.9523 16.8569C11.283 16.8569 11.5329 16.7229 11.783 16.4644L15.6473 12.5527C15.8451 12.3581 15.9335 12.1324 15.9335 11.8484C15.9335 11.3088 15.5205 10.9118 14.9726 10.9118C14.6873 10.9118 14.4384 11.0128 14.2692 11.2118L12.9623 12.5716L11.8809 13.9095L11.9894 11.4905L11.9894 5.95171C11.9894 5.36015 11.5523 4.94203 10.9523 4.94203Z"
                                fill="currentColor"/>
                        </g>
                    </svg>
                </div>
                <span>Digital Products</span>
            </NavLink>
            <NavLink to="/my-account/logout" className={"!text-red-500 sm:!mt-4"} onClick={(e) => {
                e.preventDefault()
                logout()
            }}>
                Log Out
            </NavLink>
        </nav>
    )
};


const AccountContent = (props) => {
    return (
        <div className={"account-content w-full"}>
            <Routes>
                <Route index element={<ProtectedRoute component={AccountProfile}
                                                      isRegistered={props.profile?.customer?._links?.edit}
                                                      profile={props?.profile} title={"Profile"}/>}/>
                <Route path="profile" element={<ProtectedRoute component={AccountProfile}
                                                               isRegistered={props.profile?.customer?._links?.edit}
                                                               profile={props?.profile} title={"Profile"}/>}/>
                <Route path="orders" element={<ProtectedRoute component={AccountOrders}
                                                              isRegistered={props.profile?.customer?._links?.edit}
                                                              profile={props?.profile} title={"Orders"}/>}/>
                <Route path="bids-offers" element={<ProtectedRoute component={AccountBidsOffers}
                                                                   isRegistered={props.profile?.customer?._links?.edit}
                                                                   profile={props?.profile} title={"Bids & Offers"}/>}/>
                <Route path="digital-products" element={<ProtectedRoute component={AccountDigitalProducts}
                                                                        isRegistered={props.profile?.customer?._links?.edit}
                                                                        profile={props?.profile}
                                                                        title={"Digital Products"}/>}/>
                <Route path="sign-in" element={<AccountLoginRegister {...props} title={"Sign In"}/>}/>
                <Route path="register" element={<AccountLoginRegister {...props} title={"Register"}/>}/>
                <Route path="reset-password" element={<AccountLoginRegister {...props} title={"Reset Password"}/>}/>
                <Route path="set-password" element={<AccountLoginRegister {...props} title={"Set Password"}/>}/>
            </Routes>
        </div>
    )
}


export default function AccountBlock(props) {
    const [isHydrated, setIsHyrdated] = useState(false);
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        setIsHyrdated(true)
        document.title = "My Account"
        let timer = setTimeout(() => {
            setShowLoader(true);
        }, 400);
        axios.get('/api/v2/publicusers/profile' + window.location.search)
            .then((response) => {
                setProfile(response.data);
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                clearTimeout(timer)
                setLoading(false)
                setShowLoader(false)
            })
    }, [])

    return (
        isHydrated &&
        <BrowserRouter>
            <div className={"mx-auto max-w-7xl w-full section"}>
                <div className="flex flex-col sm:flex-row">
                    {loading ?
                        showLoader && <Spinner containerClassName={"w-full mt-12"}/>
                        :
                        <>
                            {profile.registered && <AccountNavigation profile={profile}/>}
                            <Routes>
                                <Route path="/my-account/*" element={<AccountContent {...props} profile={profile}/>}/>
                            </Routes>
                        </>
                    }
                </div>
            </div>
        </BrowserRouter>
    )
};

export function formatDateToFriendlyDate(inputDate) {
    const date = new Date(inputDate);

    if (isNaN(date.getTime())) {
        return '—';
    }

    const dateOptions = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
    };

    const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    // Locale can be adjusted as needed, 'en-AU' is used here as an example
    const formattedDate = new Intl.DateTimeFormat("en-AU", dateOptions).format(date);
    const formattedTime = new Intl.DateTimeFormat("en-AU", timeOptions).format(date);

    return `${formattedDate} ${formattedTime}`;
}

export function convertUtcToLocalTime(inputDate) {
    if(!inputDate) return;
    const utc = new Date(inputDate);
    const offset = utc.getTimezoneOffset();
    return new Date(utc.getTime() - offset * 60000)
}