import {useContext} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import _get from "lodash/get";

import FormContext from "../form-context";
import {evaluate, getValue, isDisabled, isRequired} from "../utils";
import Field from "../controls/field";
import Spacer from "../controls/spacer";
import Paragraph from "../controls/paragraph";
import Block from "../controls/block";

function WebsiteField(props) {
    const context = useContext(FormContext);
    
    const required = isRequired(props, context.values);
    const disabled = isDisabled(props, context.values);
    const isNotCheckbox = props.type !== 'checkbox' && props.type !== 'switch';
    const error = _get(context.errors, props.name, false);

    const hasLabel = !!props.label;
    const id = props.id || props.name;
    const field = (
        <Field
            {...props}
            id={id}
            error={error}
            required={required}
            disabled={disabled}
        />
    )
    if (!(isNotCheckbox && hasLabel)) {
        return field;
    } else {
        return (
            <div className={"flex flex-col space-y-1"}>
                {isNotCheckbox && hasLabel ? (
                    <label htmlFor={id}>
                        {required && props.type !== 'checkbox-list' ? `${props.label}*` : props.label}
                    </label>
                ) : null}
                {field}
            </div>
        )
    }
}

function WebsiteControlGroup(props) {
    const context = useContext(FormContext);
    const fields = (props.fields || []).filter(field => evaluate(null, field.showsWhen, context.values) !== false);
    if (!fields.length) return null;
    
    return (
        <div className={classNames("space-y-2 grid grid-cols-1 gap-4 sm:space-y-0 sm:grid-cols-2", props.className)}>
            {fields.map(({fieldClassName, className, ...field}, index) => (
                <div key={`${field.name}_${index}`} className={fieldClassName}>
                    {field.type !== 'spacer' && field.type !== 'paragraph' ? (
                        <WebsiteField
                            className={classNames(className, "w-full")}
                            {...field}
                            onFieldChange={props.onFieldChange}
                        />
                    ) : null}
                    {field.type === 'spacer' ? (
                        <Spacer {...field} className={className} />
                    ) : null}
                    {field.type === 'paragraph' ? (
                        <Paragraph {...field} className={className} />
                    ) : null}
                </div>
            ))}
        </div>
    )
}

WebsiteLayout.propTypes = {
    fieldset: PropTypes.object.isRequired,
    onFieldChange: PropTypes.func,
}

WebsiteLayout.defaultProps = {
    fieldset: {},
    onFieldChange: () => {},
}

export default function WebsiteLayout({fieldset, ...props}) {
    const context = useContext(FormContext);
    
    return (
        <>
            {(fieldset.fields || []).map((field, index) => {
                const key = field.divider === true ? `${index}_divider` : `${field.name}_${index}${field.editing ? '_editing' : ''}`;

                // Handle when it evaluates to false to hide this field otherwise if it's undefined null or true then
                // show it
                if (evaluate(field.value, field.showsWhen, context.values) === false) {
                    return null;
                }

                // Handle when the field is a divider and render a hr instead
                if (field.divider === true) {
                    return (
                        <hr key={key} className={"border-panels-300 !mt-4 !mb-1 last:!mb-2"}/>
                    )
                }

                // Handle when the field is a hidden field and render just the input
                if (field.type === 'hidden') {
                    return (
                        <input
                            key={key}
                            type={field.type}
                            name={field.name}
                            value={getValue(field, context.values)}
                        />
                    );
                }

                // Render a form control
                let Control;
                switch (field.type) {
                    case 'form-layout':
                    case 'list':
                    case 'table':
                        Control = null; // Not supported
                        return;
                    case 'control-group':
                        Control = WebsiteControlGroup
                        break;
                    case 'spacer':
                        Control = Spacer;
                        break;
                    case 'paragraph':
                        Control = Paragraph;
                        break;
                    case 'block':
                        Control = Block;
                        break;
                    default:
                        Control = WebsiteField;
                        break;
                }

                return <Control key={key} {...field} onFieldChange={props.onFieldChange} />
            })}
        </>
    )
};