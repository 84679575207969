import {useState, Fragment, useContext, useRef} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {WebsiteContext} from "../../../index";
import Currency from "@autocx/forms/src/controls/currency";
import BidCountdown from "../../product-card/components/bid-countdown";
import Confetti from "react-dom-confetti";
import classNames from "classnames";
import axios from "axios";
import {AUCTIONSTATUSENUM} from "../index";

function BidButton({saleMethod, usersBid, openModal, className, disabled}) {
    const context = useContext(WebsiteContext);

    const buttonText = usersBid?.amount !== null && usersBid?.amount !== undefined
        ? <>
            Your offer: <Currency.DisplayValue currency={context.currency} value={usersBid?.amount}
                                               emptyPlaceholder=""/>
        </>
        : saleMethod?.toLowerCase() === 'tender'
            ? 'Make Offer'
            : 'Place Bid';

    return (
        <button
            disabled={disabled}
            className={classNames("button mt-1 w-full justify-center", className)}
            onClick={openModal}
        >
            {buttonText}
        </button>
    );
}

const confettiConfig = {
    elementCount: 88,
    spread: 180,
    duration: 6000,
    stagger: 10,
    width: "12px",
    height: "12px",
    // colors: ["#FFD700", "#FF8C00", "#DC143C", "#32CD32", "#00BFFF", "#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

function BidModal({isOpen, closeModal, salesEvent, setStatus, onBidSuccess, now, usersBid}) {
    const context = useContext(WebsiteContext)
    const [amount, setAmount] = useState(usersBid?.amount);
    const [reserveMet, setReserveMet] = useState(false)
    const saleMethod = salesEvent?.sellingMethod?.toLowerCase();
    const cancelButtonRef = useRef(null)
    const title = reserveMet ? 'Congratulations!' : saleMethod === 'tender' ? 'Make Offer' : 'Place Bid';
    const buttonLabel = saleMethod === 'tender' ? 'Submit Offer' : 'Place Bid';
    const inputLabel = saleMethod === 'tender' ? 'Enter offer amount' : 'Enter bid amount';

    const handleSubmit = (event) => {
        event.preventDefault();
        // Submit logic for offer or bid

        if (saleMethod === 'tender') {
            axios
                .post(`/api/v2/salesevents/lots/${salesEvent.lotId}/bids`, {amount})
                .then(({data}) => {
                    if (data === 'accepted') {
                        onBidSuccess({amount})
                        closeModal();
                    } else if (data === 'instantWin') {
                        onBidSuccess({amount, isWinner: true});
                        setReserveMet(true);
                    } else if (data === 'biddingClosed') {
                        setStatus(AUCTIONSTATUSENUM.CLOSED);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }

    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            {/*Set initial focus to Cancel button so disclaimer doesn't get hidden on mobile by keyboard*/}
            <Dialog as="div" className="relative z-10" onClose={closeModal} initialFocus={cancelButtonRef}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/30"/>
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel
                                className="relative bg-white rounded-lg p-6 max-w-sm mx-auto text-center flex flex-col items-center shadow-xl">
                                <Dialog.Title as="h3" className="!text-2xl !mb-0 font-semibold text-gray-900">
                                    {title}
                                </Dialog.Title>
                                {!reserveMet ?
                                    <>
                                        <BidCountdown salesEvent={salesEvent}
                                                      date={now + salesEvent.secondsRemaining * 1000 + 300}
                                                      warningMinutes={1}/>
                                        <form className="mt-2" onSubmit={handleSubmit}>
                                            <label htmlFor="amount"
                                                   className="text-sm font-medium text-gray-700 hidden">
                                                {inputLabel}
                                            </label>
                                            <Currency id="amount"
                                                      className="block w-full px-2 py-1 text-lg font-medium text-center border-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                                                      placeholder={inputLabel}
                                                      hidePrefix={true}
                                                      value={amount}
                                                      min="1"
                                                      currency={context.currency} name={'amount'}
                                                      onChange={(e, name, value) => setAmount(value)} required={true}/>
                                            {salesEvent.feeDescription &&
                                                <p className={"text-left my-2 text-gray-700 text-xs whitespace-pre-line"}
                                                   dangerouslySetInnerHTML={{__html: salesEvent.feeDescription}}/>}
                                            <div className="mt-4 text-center">
                                                <button
                                                    type="submit"
                                                    className="button mt-1 w-full justify-center ">
                                                    {buttonLabel}
                                                </button>
                                                <button ref={cancelButtonRef} onClick={closeModal}
                                                        className={"text-link font-semibold mt-2 text-sm"}>Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                    :
                                    <>
                                        <p className="mt-2">Your offer of <Currency.DisplayValue
                                            currency={context.currency} value={amount} emptyPlaceholder={""}/> has hit
                                            the reserve. <br/>You'll
                                            receive an email soon with all the details.</p>
                                        <button onClick={() => {
                                            setStatus(AUCTIONSTATUSENUM.CLOSED);
                                            closeModal()
                                        }}
                                                className={"text-link font-medium text-base mt-4"}>Okay
                                        </button>
                                    </>
                                }
                                <Confetti active={reserveMet} config={confettiConfig}/>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

BidModal.BidButton = BidButton;

export default BidModal;