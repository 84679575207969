import Table from "./table";
import {useContext, useEffect, useMemo, useState} from "react";
import FormContext from "../form-context";
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import _set from 'lodash/set';
import axios from "axios";

export const DEFAULT_PRODUCT_FIELDS = [
    {
        label: "Search",
        type: "keyword-search",
        value: "keyword-search"
    },
    {
        type: "currency",
        label: "Price",
        value: "unitPrice"
    }
]
const SUPPORTEDTYPES = ["checkbox", "currency", "colour-picker", "checkbox-list", "text", "number", "select", "combobox", "radio"];

export function getUserDefinedFields(userDefinedFields, fields = []) {
    userDefinedFields.forEach((userDefinedField) => {
        if (userDefinedField.userDefinedFieldsets) getUserDefinedFields(userDefinedField.userDefinedFieldsets, fields)
        else if (userDefinedField.fields) getUserDefinedFields(userDefinedField.fields, fields)
        else {
            const exists = fields.find(({value}) => value === userDefinedField.id);
            if (!exists && userDefinedField.label) fields.push({
                value: userDefinedField.id,
                type: userDefinedField.type,
                label: userDefinedField.label
            })
        }
    })
    return fields
}

const getFieldset = (fields, values, context, props) => {
    const options = fields.filter(f => SUPPORTEDTYPES.includes(f.type));
    const allOptions = _uniqBy([...DEFAULT_PRODUCT_FIELDS, ...options], (o) => o.value)
    const availableValues = values?.reduce((result, value) => {
        if (allOptions.find(o => o.value === value.id)) result.push(value)
        return result
    }, []);

    if (values?.length !== availableValues?.length) {
        const newValues = context.values;
        _set(newValues, props.name, availableValues)
        context.setContext({values: newValues})
    }

    return [
        {
            fields: [
                {
                    system: true,
                    type: "select",
                    label: "Fields",
                    name: "id",
                    required: true,
                    options: allOptions
                },
            ]
        }
    ]
}

export default function CategoryField(props) {
    const context = useContext(FormContext);
    const [fieldsets, setFieldssets] = useState();
    const categoryUuid = (() => {
        if (props.value) return props.value;
        else if (props.pathToValue) return _get(context.values, props.pathToValue);
        else return '';
    })()
    const values = _get(context?.values, props?.name);

    const fetchData = (cancelToken) => {
        Promise.all([
            axios.get('/api/v2/productTemplates', {cancelToken: cancelToken.token}),
            axios.get(`/api/v2/lookup/productTemplateUuids/inCategories/${categoryUuid}`, {cancelToken: cancelToken.token}),
        ])
            .then(([productTemplates, productTemplateUuids]) => {
                const templates = productTemplates.data;
                const uuids = productTemplateUuids.data;
                const userDefinedFieldsets = templates.reduce((result, template) => {
                    if (uuids.includes(template.id)) result.push(...template.userDefinedFieldsets)
                    return result;
                }, [])
                
                setFieldssets(getFieldset(getUserDefinedFields(userDefinedFieldsets), values, context, props))
            })
            .catch((err) => {
                if (!axios.isCancel(err)) console.log(err);
            })
    }

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        fetchData(cancelToken)
        return () => cancelToken.cancel();
    }, [categoryUuid])


    return (
        <Table {...props} fieldsets={fieldsets}/>
    );
};