import {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useLocation} from "react-router";
import axios from "axios";
import Spinner from "../../components/spinner";
import WebsiteForm, {HONEY_POT_FIELD_NAME} from "../../components/website-form";
import {WebsiteContext} from "../../index";
import Input from "@autocx/forms/src/controls/input";

export default function AccountLoginRegister(props) {
    const location = useLocation();
    const navigate = useNavigate()
    const from = location.state?.from?.pathname || location.search?.split('returnUrl=')?.[1] || "/";

    useEffect(() => {
        document.title = props?.title
        if (props.profile?.customer?._links?.edit) {
            navigate('/my-account/profile')
        }
    }, [])

    const isLogin = location.pathname.includes("sign-in");
    const isResetPassword = location.pathname.includes("reset-password");
    const isSetPassword = location.pathname.includes("set-password");


    return (
        <div className={"flex items-center justify-center sm:pt-12"}>
            <div className={"rounded-theme section section-grey border w-full m-4 sm:max-w-sm sm:m-0"}>
                <h2 className={"text-center !mb-8"}>{props?.title}</h2>
                {isResetPassword ? <ResetPassword/>
                    : isLogin ? <LoginForm from={from}/>
                        : isSetPassword ? <SetPassword reset={true}/>
                            : <RegisterForm from={from} {...props}/>}
            </div>
        </div>
    )
}

export const LoginForm = ({from, inline, setProfile, setShowingSignIn, setCurrentForm}) => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [formData, setFormData] = useState({
        emailAddress: '',
        password: ''
    });

    const handleChange = (e) => {
        setErrorMessage("")
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        axios.post('/api/v2/publicusers/authenticate', formData)
            .then(() => {
                if (inline) {
                    return axios.get('/api/v2/publicusers/profile/');
                } else {
                    location.replace(`${location.origin}${from}`);
                    return Promise.reject('Redirecting, no need to fetch profile'); // Prevents the next .then() from running
                }
            })
            .then(profileResponse => {
                setProfile(profileResponse.data);
                setShowingSignIn(false);
            })
            .catch(function (err) {
                if (err.response.status === 401) {
                    setErrorMessage('Your login details are incorrect')
                } else {
                    setErrorMessage('There was a problem logging in, please try again.');
                }
                console.log(err);
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <div>
            <form className={"flex flex-col gap-y-3"} onSubmit={handleSubmit}>
                <input type="email" placeholder="Email" autoComplete="username" name="emailAddress"
                       value={formData.email} onChange={handleChange}/>
                <Input type="password" canRevealSecret={true} placeholder="Password" autoComplete="current-password" id="current-password"
                       name="password" value={formData.password} onChange={handleChange}/>
                <button type="submit" disabled={loading} className={"button justify-center mt-2"}>
                    {loading ?
                        <>
                            <span className={"inline-block pr-4"}>Signing In...</span><Spinner
                            containerClassName={"!my-0"}/>
                        </>
                        : <>Sign In</>
                    }
                </button>
                {errorMessage && <div
                    className={"text-red-500 font-semibold leading-tight text-sm text-center animate__animated animate__headShake"}>{errorMessage}</div>}
            </form>
            {inline ?
                <>
                    <p className={"mt-2 text-center font-semibold text-sm"}>
                        <button className={"text-link"} onClick={() => setCurrentForm("resetPassword")}>Forgot Password?</button>
                    </p>
                    <p className={"mt-2 text-center text-base"}>
                        No account? You can easily register after checkout.
                    </p>
                </>
                :
                <>
                    <p className={"mt-2 text-center font-semibold text-sm"}>
                        <Link to="/my-account/reset-password">Forgot Password?</Link>
                    </p>
                    <p className={"mt-4 text-center"}>
                        Don't have an account? <Link to="/my-account/register">Register</Link>
                    </p>
                </>
            }
            
        </div>
    );
};

const RegisterForm = ({from, ...props}) => {
    const websiteContext = useContext(WebsiteContext);
    const [state, setState] = useState({
        error: false,
        errorMessage: null,
        submitted: false,
        submitting: false,
    });

    const onSubmit = (e, values) => {
        setState(previous => ({...previous, submitting: true, error: false, errorMessage: null}));

        const honeyPotElem = document.querySelector(`#${HONEY_POT_FIELD_NAME}`);

        // If there is any sign of a value on the honey pot input then ignore submission and go to success state
        if (honeyPotElem.value || honeyPotElem._valueTracker?.getValue()) {
            setState({
                error: false,
                errorMessage: null,
                submitting: false,
                submitted: true,
            })
            location.replace(`/`);
            return;
        }

        values.form = {uuid: e.target.id};
        values.node = {path: '/my-account/register'};

        return axios
            .post(`/api/v2/channels/${websiteContext.channel}/responses`, values)
            .then(() => {
                if (props?.values?.form?.formSubmission) new Function(props.values.form.formSubmission)()

                // Set time out here to wait for any code to run from the form submission if any (form.formSubmission).
                setTimeout(() => {
                    location.replace(`${location.origin}${from}`);
                }, 500)
            })
            .catch(err => {
                if (err.response.status === 409 && err.response.data) setState(previous => ({
                    ...previous,
                    error: true,
                    errorMessage: "There is already an account associated with this email.",
                    submitting: false
                }))
                else setState(previous => ({
                    ...previous,
                    error: true,
                    submitting: false,
                    errorMessage: "There was a problem registering your account, please try again."
                }))
            });
    }

    return (
        <div>
            <WebsiteForm {...state} {...props} onSubmit={onSubmit} label={"Register"}
                         submitButtonClassName={"w-full justify-center"}/>
            <p className={"mt-4 text-center"}>
                Already have an account? <Link to="/my-account/sign-in">Sign In</Link>
            </p>
        </div>
    );
};

export const ResetPassword = ({inline, setCurrentForm}) => {
    const [resetSent, setResetSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(''); 

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault(); 
        axios.post('/api/v2/publicusers/password/request-reset', {email: email})
            .then(() => {
                setResetSent(true);
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <div>
            {resetSent
                ?
                <p className={"leading-tight"}>
                    An email with password reset instructions will be sent if there's an account with the email <span
                    className={"font-semibold"}>{email}</span>.
                    <br/><br/>
                    Remember to check your spam folder too.
                </p>
                :
                <form className={"flex flex-col gap-y-3"} onSubmit={handleSubmit}>
                    <input type="email" placeholder="Email" autoComplete="username" required value={email}
                           onChange={(e) => setEmail(e.target.value)}
                    />
                    <button disabled={loading} type="submit" className={"button justify-center mt-2"}>
                        Reset Password
                    </button>
                </form>
            }
            {inline ?
                    <p className={"mt-2 text-center"}>
                        <button className={"text-link"} onClick={() => setCurrentForm("signIn")}>Back to sign in</button>
                    </p>
                :
                    <p className={"mt-2 text-center"}>
                        <Link to="/my-account/sign-in">Back to sign in</Link>
                    </p>
            }
            
        </div>
    );
};

export const SetPassword = ({email, profile, ...props}) => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState("guest")
    const [errorMessage, setErrorMessage] = useState("")
    const [formData, setFormData] = useState({
        password: ''
    });

    const handleChange = (e) => {
        setErrorMessage("")
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    useEffect(() => {
        if (profile) {
            if (profile?.registered) setState('registered')
            else if (profile?.customer?._links?.setPassword) state !== 'guest' ? setState('guest') : null
            else setState('existing')
        }
    }, [profile]);


    const url = props.reset ? '/api/v2/publicusers/password/reset' + window.location.search : '/api/v2/publicusers/password';

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        axios.post(url, formData)
            .then(() => {
                if (props.onSuccess) props.onSuccess();
                props.reset ? location.replace(`${location.origin}/my-account/`) : setState('registered')
            })

            .catch(function (err) {
                props.reset ? setErrorMessage(<div>Looks like your reset password link has expired. <Link to="/my-account/reset-password">Please try again</Link></div>) : setErrorMessage('There was a problem setting your password, please try again.');
                console.log(err);
            })
            .finally(() => {
                setLoading(false)
            })
    };

    return (
        <div>
            {state === 'guest' &&
                <form className={"flex flex-col gap-y-3"} onSubmit={handleSubmit}>
                    <input readOnly type="email" placeholder="Email" autoComplete="username emailAddress"
                           name="emailAddress" value={email} className={"opacity-0 absolute pointer-events-none"}/>
                    <Input canRevealSecret={true} type="password" disabled={loading} placeholder="Password"
                           autoComplete="new-password" required={props.reset}
                           id="new-password" name="password" value={formData.password} onChange={handleChange}/>
                    <button type="submit" disabled={loading} className={"button justify-center mt-2"}>
                        Set Password
                    </button>
                    {errorMessage && <div
                        className={"text-red-500 font-semibold leading-tight text-sm text-center animate__animated animate__headShake"}>{errorMessage}</div>}
                </form>
            }
            {state === 'registered' && <div className={"leading-tight text-center"}>Thank you, your password has
                been set.</div>}
            {state === 'existing' &&
                <div className={"leading-tight text-center"}>Looks like you have an account
                    with us, <a href='/my-account/sign-in'>log in here </a> to view all your
                    orders.</div>}
        </div>
    );
};