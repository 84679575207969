import {useContext, useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import _get from 'lodash/get';
import {arrayMove} from "@dnd-kit/sortable";

import MenuIcon from '@heroicons/react/outline/MenuIcon'
import PencilAltIcon from "@heroicons/react/outline/PencilAltIcon";
import TrashIcon from "@heroicons/react/outline/TrashIcon";

import FormLayout from "../layouts/form-layout";
import FormContext from "../form-context";
import {
    clearShowsWhenFieldValues,
    ensureOnlyOneItemFields,
    evaluate,
    getValue,
    getValuesIncludingHidden,
    prefixFieldNames,
    validateCollection
} from "../utils"
import axios from "axios";
import DividerAddButton from "../builder/divider-add-button";
import Controls from './index';

const DragHandle = SortableHandle(() => (
    <td className="px-3 py-1.5 whitespace-nowrap text-center cursor-pointer align-top">
        <MenuIcon className="h-5 w-5 text-gray-500 inline align-top" aria-hidden="true"/>
    </td>
));

const getCellValue = (field, customControls, values) => {
    let value = _get(values, field.name);
    let CellDisplayValue;
    if (Controls[field.type]?.FormTableDisplayView) {
        CellDisplayValue = Controls[field.type].FormTableDisplayView
    } else if (Controls[field.type]?.DisplayValue) {
        CellDisplayValue = Controls[field.type].DisplayValue;
    } else if (customControls[field.type]?.DisplayValue) {
        CellDisplayValue = customControls[field.type].DisplayValue;
    } else {
        CellDisplayValue = Controls.input?.DisplayValue;
    }

    return CellDisplayValue ? (<CellDisplayValue {...field} value={value}/>) : (<>&nbsp;</>);
}

const SortableItem = SortableElement(({
                                          totalCells,
                                          sortable,
                                          adding,
                                          onItemRemove,
                                          onItemSave,
                                          actions,
                                          editable,
                                          rows,
                                          ...props
                                      }) => {
    const context = useContext(FormContext);
    const [editing, setEditing] = useState(adding);
    const editFormRef = useRef();
    const formFieldsets = (props.fieldsets || []).map(fieldset => ({
        ...fieldset,
        fields: (fieldset.fields || []).filter(field => !field.readOnly)
    }));

    return (
        <tr className={"bg-white border-t"}>
            {sortable && !editing ? (<DragHandle/>) : null}
            {!editing ? (props.fieldsets || []).map(({fields = []}) => fields.filter(field => field.type !== 'hidden').map(field => (
                <td key={`${field.name}_cell`} className={classNames(
                    "px-3 py-1.5 whitespace-nowrap",
                    evaluate(field.value, field.showsWhen, context.values) === false || props.ignoreFields?.includes(field.name) ? "hidden" : null
                )}>
                    {getCellValue(field, context.customControls, context.values)}
                </td>
            ))) : null}
            {editing ? (
                <td colSpan={totalCells} className="px-3 py-1.5 whitespace-nowrap">
                    <FormLayout
                        ref={editFormRef}
                        {...props}
                        fieldsets={formFieldsets}
                        layout={"simple-stacked"}
                        className={classNames("grow bg-panels-100 rounded p-6")}
                        actions={[
                            actions.done,
                            adding ? null : actions.remove,
                            actions.cancel
                        ].filter(action => !!action)}
                        onFieldChange={(e, name, value, newConditionalForms) => {
                            // Ignore auto save when fields change on a form for a table row
                            props.onFieldChange(e, name, value, newConditionalForms, true);
                        }}
                        onActionClick={(e, action) => {
                            e.preventDefault();
                            e.stopPropagation();

                            if (action.primary) {
                                validateCollection(context, formFieldsets);

                                if (context.validate(editFormRef)) {
                                    setEditing(false);
                                    onItemSave(e);
                                }

                                return;
                            }

                            if (action.back && adding || action.destructive) {
                                onItemRemove(e);
                            } else {
                                setEditing(false);
                            }
                        }}
                    />
                </td>
            ) : null}
            {!editing ? (
                <td className="px-3 py-1.5 whitespace-nowrap align-top text-right">
                    {actions.done && editable ? (
                        <span className="cursor-pointer" onClick={() => setEditing(true)}>
                            <PencilAltIcon className="h-5 w-5 text-primary-500 inline align-top" aria-hidden="true"/>
                        </span>
                    ) : null}
                    {actions.remove ? (
                        <span className="cursor-pointer" onClick={onItemRemove}>
                            <TrashIcon
                                className={classNames(
                                    "h-5 w-5 text-gray-500 inline align-top", actions.done ? "ml-2" : null
                                )}
                                aria-hidden="true"
                            />
                        </span>
                    ) : null}
                </td>
            ) : null}
        </tr>
    )
});

const SortableList = SortableContainer(({
                                            name,
                                            onItemRemove,
                                            onItemSave,
                                            rows,
                                            fieldsets,
                                            ignoreFields,
                                            loading,
                                            ...props
                                        }) => {
    return (
        <tbody>
        {loading ? (
            <tr className={"bg-white border-t"}>
                <td className={"p-2"} colSpan={props.totalCells}>
                    <div className="flex justify-center">
                        <svg className="animate-spin h-5 w-5 text-primary-500" xmlns="http://www.w3.org/2000/svg"
                             fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    strokeWidth="4"/>
                            <path className="opacity-75" fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                        </svg>
                    </div>
                </td>
            </tr>
        ) : (
            <>
                {(rows || []).map((row, index) => {
                    const prefix = `${name}[${index}]`;
                    return (
                        <SortableItem
                            key={prefix}
                            index={index}
                            adding={row.__adding__}
                            rows={rows}
                            {...props}
                            ignoreFields={ignoreFields?.map(field => `${prefix}.${field}`)}
                            fieldsets={prefixFieldNames(fieldsets, prefix)}
                            onItemRemove={(e) => onItemRemove(e, index)}
                            onItemSave={(e) => onItemSave(e, index)}
                        />
                    );
                })}
            </>
        )}
        </tbody>
    );
});

Table.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    fieldsets: PropTypes.array,
    hiddenFields: PropTypes.object,
    name: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    editable: PropTypes.bool,
    onFieldChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    errors: PropTypes.object,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
    errorMessage: PropTypes.string,
    ignoreFields: PropTypes.arrayOf(PropTypes.string),
};

Table.defaultProps = {
    fieldsets: [],
    actions: [
        {label: "Done", primary: true},
        {label: "Remove", destructive: true},
        {label: "Cancel", back: true}
    ],
    onFieldChange: () => {
    },
}

export default function Table(props) {
    const context = useContext(FormContext);
    const tableRef = useRef();
    const containerRef = useRef();

    // Apply showsWhen expressions to filter out anything that shouldn't be showing
    const totalCells = props.fieldsets.reduce((result, fieldset) => {
        result += fieldset.fields.filter(field => !(evaluate(field.value, field.showsWhen, context.values) === false || props.ignoreFields?.includes(field.name))).length;
        return result;
    }, props.sortable ? 2 : 1);

    const actions = (props.actions || Table.defaultProps.actions).reduce((result, action) => {
        if (action.primary) {
            result.done = action;
        } else if (action.destructive) {
            result.remove = action;
        } else if (action.back) {
            result.cancel = action;
        }
        return result;
    }, {});

    // Here we are going to preload any fields that have a href defined and no options. This is so we have the data 
    // required to populate the selected value for the cell 
    const fieldsToLoad = [];
    props.fieldsets.forEach((fieldset) => {
        (fieldset.fields || []).forEach((field) => {
            if (field.href && !field.options && !(evaluate(field.value, field.showsWhen, context.values) === false)) {
                fieldsToLoad.push({...field});
            }
        });
    });

    const [state, setState] = useState({
        loading: fieldsToLoad.length > 0,
        fieldsets: props.fieldsets,
        rows: []
    });

    useEffect(() => {
        if (!state.loading) return;

        const cancelToken = axios.CancelToken.source();
        const promises = [];
        const results = {};
        for (const field of fieldsToLoad) {
            const loadFn = Controls[field.type]?.LoadOptions || context.customControls[field.type]?.LoadOptions;
            if (loadFn) {
                // When the control is a combobox then add the query param `value` so that it will get the selected
                // value
                const params = field.type === 'combobox' ? {value: getValue(field, context.values)} : undefined;
                const [promise] = loadFn(field.href, field, cancelToken, context, params);
                promises.push(promise.then(([options]) => results[field.name] = options));
            } else {
                promises.push(Promise.resolve([]));
            }
        }

        Promise
            .allSettled(promises)
            .finally(() => {
                if (!cancelToken.token.reason) {
                    setState(previous => {
                        return {
                            ...previous,
                            loading: false,
                            fieldsets: previous.fieldsets.map(fieldset => ({
                                ...fieldset,
                                fields: fieldset.fields.map(({
                                                                 href,
                                                                 groupFieldPath,
                                                                 labelFieldPath,
                                                                 valueFieldPath,
                                                                 ...field
                                                             }) => ({
                                    ...field,
                                    options: results[field.name] || field.options
                                }))
                            }))
                        }
                    });
                }
            });

        return () => cancelToken.cancel();
    }, [fieldsToLoad.length]);

    useEffect(() => {
        const rows = _get(context.values, props.name, []) || [];
        setState(previous => ({...previous, rows, fieldsets: [...props.fieldsets]}));
    }, [context.values, props.name, props.fieldsets]);

    return (
        <div
            id={props.id}
            ref={containerRef}
            className={classNames("relative", props.className)}
        >
            {props.label ? (
                <div className={"block text-sm font-medium text-gray-700"}>{props.label}</div>
            ) : null}
            {props.description ? (
                <p className="mb-0 text-xs text-gray-500">{props.description}</p>
            ) : null}
            <div className={classNames("mt-2",
                props.previewComponent ? "block" : null,
            )}>
                {props.previewComponent ? props.previewComponent(state) : null}
                <div className={classNames(
                    props.previewComponent ? 'grow mt-4' : null
                )}>
                    <div className="overflow-x-auto shadow-sm rounded bg-white border border-gray-300">
                        <table ref={tableRef} className="min-w-full table-auto">
                            <thead>
                            <tr className="bg-white">
                                {props.sortable ? (
                                    <th scope="col" className="relative px-6 py-1.5 w-8">
                                        &nbsp;
                                        <span className="sr-only">Sort Handle</span>
                                    </th>
                                ) : null}
                                {props.fieldsets.map(fieldset => (fieldset.fields || [])
                                    .filter(field => field.type !== 'hidden')
                                    .map((field, index) => (
                                        <th key={`${field.name}_header`} scope="col" className={classNames(
                                            "column-header px-3 py-1.5 whitespace-nowrap",
                                            index === 0 ? "w-full" : null,
                                            evaluate(field.value, field.showsWhen, context.values) === false || props.ignoreFields?.includes(field.name) ? "hidden" : null
                                        )}>{field.label}</th>
                                    )))
                                }
                                <th scope="col"
                                    className={classNames("relative px-3 py-1.5", actions.done && actions.remove ? "w-20" : "w-10")}>
                                    &nbsp;
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                            </thead>
                            {state.rows.length ? (
                                <SortableList
                                    {...props}
                                    loading={state.loading}
                                    fieldsets={state.fieldsets}
                                    actions={actions}
                                    useDragHandle
                                    rows={state.rows}
                                    totalCells={totalCells}
                                    getContainer={() => {
                                        let parent = containerRef.current.parentElement;
                                        while (!(parent.tagName === 'BODY' || parent.scrollHeight > parent.offsetHeight)) {
                                            parent = parent.parentElement;
                                        }

                                        return parent || document.body;
                                    }}
                                    helperClass={"sort-helper border-none"}
                                    // Handles a UI issue with table cell widths due to how the sortable component handles 
                                    // cloning the helper element and the fact that we are using a table. This code fixes the
                                    // widths of the cells in the helper
                                    onSortStart={({node}) => {
                                        // Adds z-index to sort helper to handle cases when rendering this control in a modal
                                        const helper = document.getElementsByClassName("sort-helper")[0];
                                        helper.style.zIndex = 50000;

                                        const tds = helper.childNodes;
                                        let width = 0;
                                        node.childNodes.forEach((node, idx) => {
                                            width += node.offsetWidth;
                                            tds[idx].style.width = `${node.offsetWidth}px`
                                        });
                                    }}
                                    onItemSave={(e, index) => {
                                        const values = _get(context.values, props.name, []);
                                        delete values[index].__adding__;

                                        // Ensure that fields that only allow one row to have a value are set correctly.
                                        ensureOnlyOneItemFields(props, values, index);

                                        // Remove any field values where they are not showing
                                        clearShowsWhenFieldValues(props.fieldsets, values[index]);

                                        props.onFieldChange({target: tableRef.current}, props.name, values);
                                    }}
                                    onItemRemove={(e, index) => {
                                        if (state.rows[index].__adding__ || confirm('Are you sure?')) {
                                            const values = _get(context.values, props.name, []);
                                            values.splice(index, 1);
                                            props.onFieldChange({target: tableRef.current}, props.name, values);
                                        }
                                    }}
                                    onSortEnd={({oldIndex, newIndex}) => {
                                        const values = _get(context.values, props.name, []);
                                        props.onFieldChange({target: tableRef.current}, props.name, arrayMove(values, oldIndex, newIndex));
                                    }}
                                />
                            ) : null}
                        </table>
                        {actions.done ? (
                            <div className={"sticky left-0 bottom-0"}>
                                <DividerAddButton
                                    label={"Add Row"}
                                    onClick={e => {
                                        const values = _get(context.values, props.name, []);
                                        let item = getValuesIncludingHidden(state.fieldsets, props.hiddenFields);
                                        item.__adding__ = true;
                                        if (props.onItemAdd) item = props.onItemAdd(e, props.name, values, item)
                                        props.onFieldChange({target: tableRef.current}, props.name, [].concat(values, [item]), null, true);
                                    }}
                                    className={"w-[calc(100%-24px)] mx-auto my-2"}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};