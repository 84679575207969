import {useContext, useState} from "react";
import axios from "axios";

import Design1 from './design-1';
import {WebsiteContext} from "../../index";
import {HONEY_POT_FIELD_NAME} from "../../components/website-form";

export default function FormBlock(props) {
    const websiteContext = useContext(WebsiteContext);
    const [state, setState] = useState({
        error: false,
        errorMessage: null,
        submitted: false,
        submitting: false,
    });

    let Design;
    switch (props.values?.design) {
        case 0:
            Design = Design1
            break;
        default:
            Design = Design1
    }

    return (
        <Design
            {...props}
            submitted={state.submitted}
            label={props?.values?.action?.label}
            submitting={state.submitting}
            error={state.error}
            errorMessage={state.errorMessage}
            onSubmit={(e, values) => {
                setState(previous => ({...previous, submitting: true, error: false, errorMessage: null}));

                const honeyPotElem = document.querySelector(`#${HONEY_POT_FIELD_NAME}`);

                // If there is any sign of a value on the honey pot input then ignore submission and go to success state
                if (honeyPotElem.value || honeyPotElem._valueTracker?.getValue()) {
                    setState({
                        error: false,
                        errorMessage: null,
                        submitting: false,
                        submitted: true,
                    })
                    return;
                }

                values.form = {uuid: e.target.id};
                values.node = {uuid: websiteContext.node.uuid};

                return axios
                    .post(`/api/v2/channels/${websiteContext.channel}/responses`, values)
                    .then(() => {
                        if (props?.values?.form?.formSubmission) new Function(props.values.form.formSubmission)()
                        setState(previous => ({...previous, submitted: true, submitting: false}))
                    })
                    .catch(err => {
                        if (err.response.status === 409 && err.response.data) setState(previous => ({
                            ...previous,
                            error: true,
                            errorMessage: "There is already an account associated with this email.",
                            submitting: false
                        }))
                        else setState(previous => ({...previous, error: true, submitting: false}))
                    });
            }}
        />
    )
}