import classNames from 'classnames';
import Block from '../block'
import {
    backgroundColour,
    backgroundPattern,
    marginForTextAlignment,
    setTitleDecorationByClass, setTitleDecorationByElement,
    textAlignment
} from "../../utils/block-utils";


import WebsiteForm from "../../components/website-form";

export default function Design1(props) {
    return (
        <Block
            {...props}
            className={classNames(
                props.className,
                !props.values.options?.inset ? backgroundPattern(props) : null,
                !props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null)
            }
        >
            <div
                className={classNames(
                    "mx-auto max-w-7xl section flex flex-col",
                    props.values.options?.position === 1 ? 'sm:flex-col items-center' : 'sm:flex-row items-start',
                    props.values.options?.inset ? 'rounded-theme' : null,
                    props.values.options?.inset ? backgroundPattern(props) : null,
                    props.values.options?.inset ? backgroundColour(props.values.options?.backgroundColour) : null
                )}
            >
                {(props.values?.title || props.values?.text) && <div className={classNames("w-full",
                    props.values.options?.position === 1 ? 'sm:w-3/5' : 'sm:w-1/2',
                    textAlignment(props))}>
                    {props.values?.title ? (
                        <>
                            <h3 className={classNames("relative", setTitleDecorationByClass(props.values?.options?.titleDecoration))}>{props.values.title}</h3>
                            {props.values?.options?.titleDecoration &&
                                setTitleDecorationByElement(props.values?.options?.titleDecoration)
                            }
                        </>
                    ) : null}
                    {props.values?.text ? (
                        <div dangerouslySetInnerHTML={{__html: props.values.text}}
                             className={classNames("rich-text mb-4", marginForTextAlignment(textAlignment(props)))}
                        />
                    ) : null}
                </div>}
                <div className={classNames("sm:flex sm:items-center w-full flex-col", props.values.options?.position === 1 ? 'sm:w-3/5' : 'sm:w-1/2',)}>
                    {props.values?.form?.uuid || props.values?.form?._id ? (
                        <WebsiteForm {...props}/>
                    ): (
                        <p>
                            No form has been selected
                        </p>
                    )}
                </div>
            </div>
        </Block>
    )
}